import { InjectionToken, Injector } from '@angular/core';
import { BaseAutocompleteItemCelluloidComponent } from './autocomplete-item-celluloid.component';

/**
 * Token for the Autocomplete item
 */
export const AUTOCOMPLETE_ITEM_CELLULOID_ITEM_DATA_INJECTION_TOKEN = new InjectionToken<any>('ITEM_DATA_INJECTION_TOKEN');
/**
 * token for the ('extra') data of the
 */
export const AUTOCOMPLETE_ITEM_CELLULOID_EXTRA_DATA_INJECTION_TOKEN = new InjectionToken<any>('EXTRA_DATA_INJECTION_TOKEN');

export const AUTOCOMPLETE_ITEM_CELLULOID_COMPONENT_REF_INJECTION_TOKEN = new InjectionToken<any>('DATA_SOURCE_REF_INJECTION_TOKEN');


export type BaseAutocompleteItemCelluloidComponentType<I = any, T = any> = new (...args: any[]) => BaseAutocompleteItemCelluloidComponent<I, T>;

export interface AutocompleteItemCelluloidComponentData<I = any, T = any> {
  component: BaseAutocompleteItemCelluloidComponentType<I, T>;
  data?: T;
  /**
   * if falsy then set by the component
   */
  __injector?: Injector;
}
