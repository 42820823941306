import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, skip, take } from 'rxjs/operators';
import { AutocompleteComponent } from './autocomplete.component';

export interface AutocompleteItem<T = any> {
  value: any;
  label?: string;
  labelTranslationKey?: string;
  selected?: boolean;
  focused?: boolean;
  hidden?: boolean;
  freeText?: boolean;
  injectedRenderingData?: T;
}

export interface AutocompleteDataSourceOptions<T = any> {
  /**
   * user is not able to input text and ux is that of a dropdown
   * other options like "strict", "filterItems", "customFilterFunction" or "userInputToValueFunction"
   * are unnecessary if this flag is true
   */
  simulateDropdown?: boolean;
  /**
   * menu is scrollable if more items exists than the value: TODO // NOSONAR
   */
  maxVisibleItems?: number;
  /**
   * if strict is true, only given items can be selected
   */
  strict?: boolean;
  /**
   * a given translation key can be displayed by the component if strict mode is active and there are no items
   */
  noVisibleItemsTranslationKey?: string;
  /**
   * if component has no label, this key will be translated and set as label
  */
  fallbackLabelTranslationKey?: string;
  /**
   * flag for filter items to match current user input
   */
  filterItems?: boolean;
  /**
   * by default user input matches items only case sensitive
   */
  caseInsensitiveMatching?: boolean;
  /**
   * function, which decides if current user input (1. arg) makes an item (2. arg) hide (return false) or not (return true).
   * 3. arg: item, which is considered to match the current user input
   */
  customFilterFunction?: (currentUserInput: string, item: AutocompleteItem<T>, matchingItem: AutocompleteItem) => boolean;
  /**
   * generated Autocomplete items (for example: free text items) will call the given function in order to get the value.
   * Otherwise the value will be identical to the label
   */
  userInputToValueFunction?: (text: string) => any;
  /**
   * generated Autocomplete items (for example: free text items) will call the given function in order to get the value.
   * Otherwise the value will be identical to the label
   */
  valueToUserInputFunction?: (value: any) => string;
  /**
   *
   */
  onElementBlur?: (e: FocusEvent, selectedItem?: AutocompleteItem) => void;
}

export class AutocompleteDataSource<T = any> {

  private _options = new BehaviorSubject<AutocompleteDataSourceOptions>(null);
  private _items = new BehaviorSubject<AutocompleteItem<T>[]>([]);
  private userInputSubscription: Subscription;
  // note: this is used as a normal variable, in which the current free text item is stored and updated with every
  // change of the user input - it does not need to be a subject but it does not hurt
  private _freeTextItemBehaviorSubject = new BehaviorSubject<AutocompleteItem>(null);

  private commandEmitterBehaviorSubject = new BehaviorSubject<string>('');

  userInput$ = new BehaviorSubject<string>('');

  get options(): AutocompleteDataSourceOptions {
    return this._options.value;
  }

  set options(value: AutocompleteDataSourceOptions) {
    this._options.next(value);
  }

  get items$(): Observable<AutocompleteItem<T>[]> {
    return this._items.asObservable();
  }

  get visibleItems$(): Observable<AutocompleteItem<T>[]> {
    return this.items$.pipe(map(items => (items.filter(item => !item.hidden))));
  }

  get items(): AutocompleteItem<T>[] {
    return this._items.value;
  }

  set items(value: AutocompleteItem<T>[]) {
    this._items.next(value);
  }

  get selectedItem$(): Observable<AutocompleteItem<T>> {
    return this.items$.pipe(
      map(items => {
        // if there exists a free text item then no need to search for a selected item
        // free text items have a higher priority
        const selItem = this._freeTextItemBehaviorSubject.value || items.find(item => item.selected);
        return selItem;
      }),
      distinctUntilChanged((a, b) => {
        return a?.value === b?.value;
      })
    );
  }

  get focusedItem$(): Observable<AutocompleteItem<T>> {
    return this.items$.pipe(map(items => (items.find(item => item.focused))));
  }

  onremove$ = new ReplaySubject<AutocompleteComponent>();

  get selectedItem(): AutocompleteItem<T> {
    let selectedItem: AutocompleteItem<T>;
    this.selectedItem$.pipe(take(1)).subscribe(seli => (selectedItem = seli));
    return selectedItem;
  }

  get commandEmitter(): Observable<string> {
    return this.commandEmitterBehaviorSubject.asObservable();
  }

  constructor(options: AutocompleteDataSourceOptions, items: AutocompleteItem<T>[]) {
    this.options = options || {};
    this.items = items;
  }

  /**
   * Note: is called by the component automatically
   */
  init() {
    this.complete();

    this.userInputSubscription = this.userInput$.pipe(skip(1)).subscribe(userInput => {

      this._freeTextItemBehaviorSubject.next(void 0);

      const isStrictMode = this.options.strict;
      const matchingItem = this.items.find(item => this.equalText(item.label, userInput));

      // if there is a matching item and case insensitive is selected then there is a chance that the user input is not matching
      // remedy it by savely overwrite component
      if (matchingItem && this.options.caseInsensitiveMatching) {
        this.sendSaveWriteCommand(matchingItem.label);
      }

      let numHidden = 0;

      // DESELECT ALL items and filter items if the flag is true
      if (this.options.filterItems) {
        this.items.forEach(item => {
          item.selected = false;
          if (!this.filterItem(userInput, item, matchingItem)) {
            item.hidden = true;
            numHidden++;
          } else {
            item.hidden = false;
          }
        });
      } else {
        this.deselectAll();
      }

      // if it is strict mode
      if (isStrictMode) {

        // select only if there is a matching item
        if (matchingItem) {
          // select the matching item
          matchingItem.selected = true;
        }

      } else {
        // in free text mode...

        // if there is a matching item then select it
        if (matchingItem) {
          matchingItem.selected = true;
        } else {

          // else save the current user input if it is at least 1 character long
          if (typeof userInput === 'string' && userInput.length > 0) {
            const freeTextItem: AutocompleteItem = this.createFreeTextItemWith('label', userInput);
            freeTextItem.selected = true;
            this._freeTextItemBehaviorSubject.next(freeTextItem);
          }
        }
      }

      this.updateItems();

    });

  }

  /**
   * Note: is called by the component automatically if completeDataSourcesOnEndOfUse flag is set
   */
  complete() {
    this.userInputSubscription?.unsubscribe();
  }

  focusUp(): AutocompleteItem<T> {

    const visibleItems = this.items.filter(item => !item.hidden);
    const indexOfFocusedItem = visibleItems.findIndex(item => item.focused);

    let nextIndex = -1;

    // if an item was already focused
    if (indexOfFocusedItem >= 0) {

      // deactivate focus of the old item
      visibleItems[indexOfFocusedItem].focused = false;

      // focus on previous visible item
      nextIndex = indexOfFocusedItem - 1;
      if (nextIndex < 0) {
        nextIndex = visibleItems.length - 1;
      }

    } else {
      // if not - focus last visible item
      nextIndex = visibleItems.length - 1;
    }

    if (nextIndex >= 0) {
      visibleItems[nextIndex].focused = true;
      this.updateItems();
      return visibleItems[nextIndex];
    }

    return null;

  }

  focusDown(): AutocompleteItem<T> {

    const visibleItems = this.items.filter(item => !item.hidden);
    const indexOfFocusedItem = visibleItems.findIndex(item => item.focused);

    let nextIndex = -1;

    // if an item was already focused
    if (indexOfFocusedItem >= 0) {

      // deactivate focus of the old item
      visibleItems[indexOfFocusedItem].focused = false;

      // focus on next visible item
      nextIndex = indexOfFocusedItem + 1;

      if (nextIndex >= visibleItems.length) {
        nextIndex = 0;
      }

    } else {
      // if not - focus first visible item
      nextIndex = 0;
    }

    if (nextIndex >= 0) {
      visibleItems[nextIndex].focused = true;
      this.updateItems();
      return visibleItems[nextIndex];
    }

    return null;

  }

  getFocusedItem() {
    return this.items.find(item => item.focused);
  }

  removeItemFocus() {
    this.items.forEach(item => item.focused = false);
  }

  createFreeTextItemWith(withType: 'label' | 'value', arg: any): AutocompleteItem<T> {
    const freeTextItem: AutocompleteItem<T> = {
      value: (withType === 'label' && this.options.userInputToValueFunction) ? this.options.userInputToValueFunction(arg) : arg,
      label: (withType === 'value' && this.options.valueToUserInputFunction) ? this.options.valueToUserInputFunction(arg) : (arg + ''),
      freeText: true
    };

    return freeTextItem;
  }

  selectItem(item: AutocompleteItem<T>, withUpdate?: boolean) {
    this.items.forEach(item => item.selected = false);
    item.selected = true;

    if (item.freeText && !this.options.strict) {
      this._freeTextItemBehaviorSubject.next(item);
      this.sendSaveWriteCommand(item.label);
    }

    if (withUpdate) {
      this.updateItems();
    }
  }

  selectItemWithAttribute(attr: 'label' | 'value', targetValue: any, withUpdate?: boolean) {
    const item = this.items.find(item => (item[attr] === targetValue));
    if (item) {
      this.selectItem(item, false);
    }

    if (withUpdate) {
      this.updateItems();
    }
  }

  getSelectedItem(): AutocompleteItem<T> {
    let item: AutocompleteItem<T>;
    this.selectedItem$.pipe(take(1)).subscribe(tmp => (item = tmp));
    return item;
  }

  deselectAll(withUpdate?: boolean) {
    this.items.forEach(item => item.selected = false);
    this._freeTextItemBehaviorSubject.next(null);

    if (withUpdate) {
      this.updateItems();
      this.sendWriteCommand('');
    }
  }

  isUserInputAllowed(testUserInput?: string): boolean {

    if (this.options.simulateDropdown) {
      return false;
    }

    testUserInput ||= this.userInput$.value;
    let visibleItems: AutocompleteItem<T>[];
    this.visibleItems$.pipe(take(1)).subscribe(_visItems => visibleItems = _visItems);
    return visibleItems.some(_visItem => (this.equalText(_visItem.label, testUserInput)));
  }

  private updateItems() {
    this._items.next(this._items.value);
  }

  private equalText(a: string, b: string): boolean {
    return (typeof a === 'string' && typeof b === 'string' && ((a === b) || (this.options.caseInsensitiveMatching && a.toLowerCase() === b.toLowerCase())));
  }

  /**
   * returns true if the current user input allows the current item to be displayed, else current items is hidden
   * "matchingItem" is truthy if the current user input matches an item already -> helps decision sometimes
   */
  private filterItem(userInput: string, item: AutocompleteItem<T>, matchingItem?: AutocompleteItem<T>): boolean {

    if (this.options.simulateDropdown) {
      // the option to filter items should be false if simulate dropdown but it is not a must
      // so in order to always simulate a dropdown - never hide any item -> always return true
      return true;
    }

    if (this.options.customFilterFunction) {
      return this.options.customFilterFunction(userInput, item, matchingItem);
    } else {
      const label = item.label.toLowerCase();
      const userInputLowercased = userInput.toLowerCase();
      return label.startsWith(userInputLowercased);
    }
  }

  manipulateItems(fn?: () => void) {
    fn?.();
    this.updateItems();
  }

  sendOpenCommand() {
    this.commandEmitterBehaviorSubject.next('openMenu:true');
  }

  sendCloseCommand() {
    this.commandEmitterBehaviorSubject.next('closeMenu:true');
  }

  sendWriteCommand(text: string) {
    this.commandEmitterBehaviorSubject.next('write:' + text);
  }

  sendSaveWriteCommand(text: string) {
    if (typeof text === 'string') {
      this.commandEmitterBehaviorSubject.next('saveWrite:' + text);
    }
  }

  sendFocusCommand() {
    this.commandEmitterBehaviorSubject.next('focus:true');
  }

  /**
   *
   */
  triggerOnNextRemove() {
    return this.onremove$.pipe(take(1));
  }

}
